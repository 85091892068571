// 分享
<template>
  <el-popover placement="bottom" trigger="hover">
    <vue-qr :text="Url" :size="150"></vue-qr>
    <el-button slot="reference" type="text" icon="el-icon-share">分享</el-button>
  </el-popover>
</template>
<script>
import vueQr from 'vue-qr'
export default {
  name: 'Share',
  components: {
    vueQr
  },
  data () {
    return {
      Url: window.location.href
    }
  }
}
</script>
<style lang="scss" >
.el-popover {
  padding: 0;
}
</style>