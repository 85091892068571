/** 文章详情 */
<template>
  <div class="details content">
    <el-breadcrumb v-if="$isPc" separator-class="el-icon-arrow-right">
      <template v-if="type=='policy'">
        <el-breadcrumb-item :to="{ path: '/policy' }">政策</el-breadcrumb-item>
        <el-breadcrumb-item>政策详情</el-breadcrumb-item>
      </template>
      <template v-else>
        <el-breadcrumb-item :to="{ path: '/news' }">新闻动态</el-breadcrumb-item>
        <el-breadcrumb-item>新闻动态详情</el-breadcrumb-item>
      </template>
    </el-breadcrumb>
    <div class="justify-between pb-30">
      <section class="details-left flex-1">
        <div v-if="Object.keys(article).length" class="details-main back-fff border-r8 mb-30">
          <h2 class="details-title">{{article.title}}</h2>
          <p class="details-record flex-wrap justify-around">
            <!-- <Share v-if="$isPc" /> -->
            <span>发布时间：{{article.created_at|filterTime}}</span>
            <template v-if="type=='policy'">
              <span>发布来源：{{article.article_unit}}</span>
              <span>浏览量：{{article.read_num}}</span>
              <el-button type="text" @click="collectBtn">收藏：
                <i :class="[isCollect?'el-icon-star-on':'el-icon-star-off',{'is-active-collect':isCollect}]"></i>
              </el-button>
            </template>
            <template v-else>
              <span>来源信息：{{article.article_source}}</span>
            </template>
          </p>
          <el-divider></el-divider>
          <div class="details-con" v-html="article.article_content"> </div>
        </div>
        <el-empty v-else description="暂无该文章"></el-empty>
      </section>
      <!-- 更多相关文章 -->
      <section v-if="$isPc" class="details-right flex-1 ml-20">
        <div class="details-other back-fff border-r8">
          <p class="details-other-title">{{type=='policy'?'最新政策':'最新动态'}}</p>
          <ul class="details-other-list">
            <li v-for="(item,index) in articleOther" :key="index" class="details-other-item">
              <router-link :to="{ path: '/article_details', query: { id: item.id,type } }"
                class="details-other-item text-ellipsis">
                {{item.title}}</router-link>
            </li>
          </ul>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import { addReadArticle, getArticleDetails, getNewArticle, collectArticle, setShare } from '@/api/article'
import { getItem } from '@/utils/storage'
import wx from 'weixin-js-sdk'
import Share from '@/components/Share.vue'
export default {
  name: 'ArticleDetails',
  components: {
    Share
  },
  data () {
    return {
      type: 'policy',
      isCollect: false,
      article: {},//文章详情
      articleOther: [],//相关文章
    }
  },
  watch: {
    '$route.query.id': {
      handler (newVal, oldVal) {
        this.getArticleDetails()
        this.getNewArticle()
      },
      deep: true
    }
  },
  mounted () {
    this.type = this.$route.query.type
    this.getArticleDetails()
    this.getNewArticle()
  },
  methods: {
    // 文章详情
    async getArticleDetails () {
      try {
        let { data } = await getArticleDetails(this.$route.query.id)
        this.article = data
        this.isCollect = !!data.collect_type
        if (this.type == 'policy') {
          this.addReadArticle()
        }
      } catch (error) { }
    },
    // 获取相关文章
    async getNewArticle () {
      try {
        let { data } = await getNewArticle({ article_type: this.type == 'policy' ? '政策' : '新闻动态' })
        this.articleOther = data
      } catch (error) { }
    },
    // 政策增加阅读量
    async addReadArticle () {
      try {
        await addReadArticle(this.$route.query.id)
      } catch (error) { }
    },
    // 收藏
    async collectBtn () {
      try {
        if (!this.$store.getters.userToken) return this.$message.warning('请先登录');
        let { code } = await collectArticle(this.$route.query.id)
        if (code == 200) {
          this.isCollect = !this.isCollect
        }
      } catch (error) { }
    },
    // 分享
    async setShare () {
      let links = encodeURIComponent(window.location.href.split('#')[0])
      // ios系统需使用第一次进入网站时的地址
      if (getItem('isIOS')) {
        links = encodeURIComponent(getItem('firstUrl'))
      }
      let { data } = await setShare(links)
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: data.appid, // 必填，公众号的唯一标识
        timestamp: data.timestamp, // 必填，生成签名的时间戳
        nonceStr: data.nonceStr, // 必填，生成签名的随机串
        signature: data.signature,// 必填，签名
        jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData'] // 必填，需要使用的JS接口列表
      });
      let options = {
        title: this.article.title, // 分享标题
        desc: this.$settleHtml(this.article.introduction), // 分享描述
        link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: this.$base + this.article.thumbnail, // 分享图标
      }
      wx.ready(function () {
        wx.updateAppMessageShareData({
          ...options,
          success: function () {
            // alert('分享朋友成功')
          }
        })
        wx.updateTimelineShareData({
          ...options,
          success: function () {
            // alert('分享朋友圈成功')
          }
        })
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.details-left {
  // 文章主体
  .details-main {
    padding: 40px 0;
    text-align: center;
    .details-title {
      font-size: 24px;
      line-height: 28px;
    }
    .details-record {
      margin-top: 43px;
      color: #666666;
      line-height: 30px;
      span {
        text-align: left;
        padding: 0 10px;
      }
    }
    .el-divider--horizontal {
      margin: 35px 0;
    }
  }
}

@media (max-width: 767px) {
  .details-left {
    .details-main {
      margin-bottom: 0;
      .details-title {
        font-size: 20px;
        line-height: 24px;
      }
      .details-record {
        margin-top: 25px;
      }
      .el-divider--horizontal {
        margin: 30px 0 25px;
      }
    }
  }
}
</style>